
import { Options, Vue } from "vue-property-decorator";
import { useIonRouter } from "@ionic/vue";

@Options({
  components: {},
})
export default class PlaygroundBlock extends Vue {
  private ionRouter = useIonRouter();

  public onClickPlaygroundCard(): void {
    this.ionRouter.navigate("/playground", "forward", "push");
  }
}
